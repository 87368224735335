import { Region } from '@hiwaldo/sdk';
import { ButtonInputProps, CheckoutUpsellProps } from '../../../components';
import { BasketDataState } from '../../../state';
import { BasketProductVariantEffect } from '../../basket';

export interface UpsellEffect {
  checkout: CheckoutUpsellProps | undefined;
  basket: CheckoutUpsellProps | undefined;
}

export const useUpsellEffect = (
  basketDataState: BasketDataState,
  basketProductVariant: BasketProductVariantEffect,
  region: Region,
  disabled: boolean
): UpsellEffect | undefined => {
  const dropsActive = false;

  const tagline = 'Have you tried our latest?';
  const title = 'Hydration Drops';
  const image = '/images/products/drops/hydration-drops-white-bg.png';
  const description =
    'Formulated with 100% natural active ingredients and compatible with contact lenses.';

  const addPromoProductProps: ButtonInputProps = {
    id: 'checkout-promo-product-button',
    name: 'checkout-promo-product-submit',
    type: 'button',
    template: 'buttonInputDefault',
    label: 'ADD TO CART FOR $13.00',
    isLoading: basketProductVariant.loading,
    styleClasses: ['buttonInputDiscount'],
    isDisabled: disabled,
    onClickHandler: () =>
      basketProductVariant.addProductVariant({
        cartId: basketDataState.cart?.id || 0,
        productVariantId: 216,
        quantity: 1,
      }),
  };

  const checkout: CheckoutUpsellProps | undefined =
    !basketDataState.includesDrops && region === Region.US
      ? {
          id: 'checkout-upsell',
          template: 'checkoutUpsellDefault',
          styleClasses: ['checkoutUpsellBase'],
          tagline,
          title,
          description,
          image,
          addPromoProductProps,
        }
      : undefined;

  const basket: CheckoutUpsellProps | undefined =
    !basketDataState.includesDrops && region === Region.US
      ? {
          id: 'basket-upsell',
          template: 'checkoutUpsellBasket',
          styleClasses: ['checkoutUpsellBasket'],
          tagline,
          title,
          description,
          image,
          addPromoProductProps,
        }
      : undefined;

  if (dropsActive) {
    return {
      checkout,
      basket,
    };
  } else {
    return undefined;
  }
};
