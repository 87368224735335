import React from 'react';
import { SlickCarouselMolecule } from '../../molecules';

import { shopOurProducts } from '../../../data/pages/home/our-products';
import { homeOurProductsSetting } from '../../../enums';
import { useWindow } from '../../../effects';
import { useRouter } from 'next/router';

export const HomeOurProductsOrganism = (props: { isPromoActive: boolean }) => {
  const { region, width } = useWindow();
  const router = useRouter();

  function CustomNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: `${region !== 'US' && width! > 1024 ? 'none' : 'block'} `,
          backgroundColor: 'transparent',
          width: '56px',
          height: '56px',
          backgroundImage: 'url("/images/home/icons/arrow_next.svg")',
          top: '180px',
          right: '-2%',
          zIndex: '10',
        }}
        onClick={onClick}
      />
    );
  }

  function CustomPrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: `${region !== 'US' && width! > 1024 ? 'none' : 'block'} `,
          backgroundColor: 'transparent',
          backgroundImage: 'url("/images/home/icons/arrow_previous.svg")',
          width: '56px',
          height: '56px',
          cursor: 'pointer',
          top: '180px',
          right: '3%',
          zIndex: '10',
        }}
        onClick={onClick}
      />
    );
  }

  let shopOurProductsRegion;
  let containerClassName = 'md:w-[20rem] lg:w-[79%]';
  let buttonClassName = 'btn-cta-uk';
  let sliderSettings = {
    ...homeOurProductsSetting,
    nextArrow: (
      <CustomNextArrow class="cursor-pointer right-3/20 lg:right-1/20 top-1/2" />
    ),
    prevArrow: <CustomPrevArrow class="cursor-pointer left-0 top-1/2" />,
  };

  switch (region) {
    case 'US':
      shopOurProductsRegion = shopOurProducts.OUR_PRODUCTS_US.filter(
        (product) => product.active === true
      );
      containerClassName = 'lg: w-full justify-content-center';
      buttonClassName = 'btn-cta-hbp';
      sliderSettings = {
        lazyLoad: 'progressive',
        arrows: true,
        autoplay: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 0,
        centerMode: true,
        mobileFirst: true,
        swipe: true,
        centerPadding: '1px',
        responsive: [
          {
            breakpoint: 2500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 0,
              centerMode: false,
              variableWidth: true,
            },
          },
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 0,
              centerMode: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 0,
              centerMode: true,
            },
          },
          {
            breakpoint: 660,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
            },
          },
        ],
        nextArrow: (
          <CustomNextArrow class="cursor-pointer right-3/20 lg:right-1/20 top-1/2" />
        ),
        prevArrow: <CustomPrevArrow class="cursor-pointer left-0 top-1/2" />,
      };
      break;
    default:
    case 'UK':
      shopOurProductsRegion = shopOurProducts.OUR_PRODUCTS_UK.filter(
        (product) => product.active === true
      );
      break;
    case 'EU':
      shopOurProductsRegion = shopOurProducts.OUR_PRODUCTS_EU.filter(
        (product) => product.active === true
      );
      break;
    case 'SE':
      shopOurProductsRegion = shopOurProducts.OUR_PRODUCTS_SE.filter(
        (product) => product.active === true
      );
      break;
  }

  return (
    <section
      className="w-full max-w-[1600px] xl:mx-auto pt-16 pb-10 bg-white overflow-hidden max-h-campaign-screen home-our-products-slider"
      data-cy="home-our-products"
    >
      <h2
        className="text-center pb-5 text-4xl
     font-waldo-regular 
     text-header ml-0 lg:ml-5"
      >
        Shop our <span className="font-waldo-header">doctor recommended</span>{' '}
        products
      </h2>
      <div className={`${containerClassName} w-full mx-auto`}>
        <SlickCarouselMolecule settings={sliderSettings}>
          {shopOurProductsRegion.map((productData) => (
            <div
              className="relative mb-10 px-5 w-[18rem] h-[30rem] home-our-products-slide"
              key={productData.link}
            >
              <div
                className="h-full w-full flex flex-col justify-between"
                onClick={() => router.push(productData.link)}
              >
                {productData.promotion && props.isPromoActive && (
                  <div
                    className={`absolute top-[16px] right-[30px] ${
                      productData.link.includes('vitamin')
                        ? 'md:right-[44px]'
                        : 'md:right-[35px]'
                    }`}
                  >
                    <div className="bg-waldo-blush px-4 py-2 rounded-md text-waldo-blue w-12 inline font-waldo-sans-bold">
                      {productData.tag}
                    </div>
                  </div>
                )}

                <img
                  className={`h-[250px] w-[250px] bg-waldo-dirty-white ${
                    productData.image === 'eyecare_starter_kit.png'
                      ? 'esk-slick-padding'
                      : ''
                  }`}
                  src={`/images/home/products/${productData.image}`}
                  alt={productData.title}
                />

                <div className="flex flex-col justify-between h-full mt-4">
                  <div className="flex flex-col h-full">
                    <p className="text-lg text-waldo-blue lg:text-smallTitle font-semibold lg:font-medium">
                      {productData.title}
                    </p>
                    <p
                      className={`text-lg text-waldo-blue lg:text-smallTitle font-semibold lg:font-medium ${
                        !productData.titleExt ? 'w-[8px] h-[8px]' : ''
                      }`}
                    >
                      {productData.titleExt}
                    </p>
                    <p
                      className={
                        'font-light text-lg summary w-64 h-[8px] font-waldo-sans-light'
                      }
                    >
                      {productData.summary.match(/\+/)
                        ? productData.summary.split('+')[0].trim()
                        : productData.summary}
                    </p>
                    {productData.summary.match(/\+/) ? (
                      <p
                        className={
                          'font-light text-lg summary w-64 h-[8px] mt-5 font-waldo-sans-light'
                        }
                      >
                        + {productData.summary.split('+')[1].trim()}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  {!!(region === 'US') && (
                    <p className="">{productData.pricing}</p>
                  )}
                </div>

                {!!(region === 'US') && (
                  <a
                    className={`button w-[10rem] h-[6rem] mt-4 flex items-center justify-center ${buttonClassName}`}
                    href={productData.btnLink}
                  >
                    {productData.btnLabel}
                  </a>
                )}
              </div>
            </div>
          ))}
        </SlickCarouselMolecule>
      </div>
    </section>
  );
};
