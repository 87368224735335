import React from 'react';
import { HomeHeroHbpProps } from './home-hero-hbp.props';
import { Region } from '@hiwaldo/sdk';
import { useWindow } from '../../../effects';
import { useResponsiveQuery } from '../../../utils';
import { ResponsiveSizes } from '../../../enums';
import { useRouter } from 'next/router';

export const HomeHeroHbpOrganism = (props: HomeHeroHbpProps) => {
  const router = useRouter();
  const { region } = useWindow();
  const isMobile = useResponsiveQuery(ResponsiveSizes.CHECKOUT_DESKTOP);

  const bannerActive = region !== 'US' ? true : false; // props.activeFeatures?.banner;

  return (
    <section
      className={`max-w-screen-1400 mx-auto pt-1 ${
        bannerActive ? 'home-hero-padding-top-banner' : 'home-hero-padding-top'
      }`}
    >
      <div className="w-full flex flex-col lg:flex-row lg:justify-around">
        {props.isPromoActive ? (
          <div className="flex flex-col items-center justify-center mt-5 mx-auto w-80 text-center mb-1.5 lg:hidden ">
            <span className="font-waldo-regular text-4xl mb-2 text-waldo-blue">
              Moisture-rich lenses for <b className="font-semibold">summer</b>
            </span>
            <>
              {region === Region.US ? (
                <p className="text-lg font-waldo-sans">
                  Vitamin B12-infused lenses for <br />
                  <b className="font-bold">
                    30% more hydration and 2x the comfort
                  </b>
                </p>
              ) : (
                <p className="text-lg font-waldo-sans">
                  Vitamin B and E infused lenses for <br />
                  <b className="font-bold">
                    30% more hydration and 2x the comfort
                  </b>
                </p>
              )}
            </>
          </div>
        ) : (
          <div className="flex flex-col items-center mb-2 lg:hidden">
            <h3 className="text-4xl mb-2">Lenses for dry eyes</h3>
            {region === Region.US ? (
              <>
                <p className="font-waldo-sans text-lg tracking-wide">
                  Vitamin B12-infused<sup>*</sup> lenses for 30%
                </p>
                <p className="font-waldo-sans text-lg tracking-wide">
                  more hydration and 2x the comfort
                </p>
              </>
            ) : (
              <>
                <p className="font-waldo-sans text-lg tracking-wide">
                  Vitamin B and E infused lenses for 30%
                </p>
                <p className="font-waldo-sans text-lg tracking-wide">
                  more hydration and 2x the comfort
                </p>
              </>
            )}
          </div>
        )}
        {props.isPromoActive ? (
          <div className="hidden lg:flex flex-col mb-2 justify-center lg:ml-6 w-75 mt-12">
            <span className="font-waldo-regular text-5xl mb-3 text-waldo-blue">
              Moisture-rich lenses for <b className="font-semibold">spring</b>
            </span>
            <>
              {region === Region.US ? (
                <p className="text-xl font-waldo-sans w-10/12">
                  Vitamin B12-infused lenses for{' '}
                  <b className="font-semibold">
                    30% more hydration and 2x the comfort.
                  </b>
                </p>
              ) : (
                <p className="text-xl font-waldo-sans w-10/12">
                  Vitamin B and E infused lenses for 30% more hydration and 2x
                  the comfort, designed for dry eyes.
                </p>
              )}
            </>

            {!!(region === 'US') && (
              <a
                className={`w-9/12 flex button-hero-home mt-8 font-waldo-header ${
                  region === Region.US ? 'btn-cta' : 'btn-cta-uk'
                }`}
                aria-label="Try our lenses for free"
                onClick={() => router.push(props.heroHbpContent.buttonLink)}
              >
                {props.heroHbpContent.buttonLabel}
              </a>
            )}
          </div>
        ) : (
          <div className="hidden lg:flex flex-col mb-2 justify-center lg:ml-6">
            <h3 className="text-6xl lg:text-5xl xl:text-6xl">Lenses for</h3>
            <h3 className="text-6xl lg:text-5xl xl:text-6xl mb-4">dry eyes</h3>
            {region === Region.US ? (
              <>
                <p className="text-lg">
                  Vitamin B12-infused<sup>*</sup> lenses
                </p>
                <p className="text-lg">for 30% more hydration and 2x</p>
                <p className="text-lg">the comfort</p>
              </>
            ) : (
              <>
                <p className="font-waldo-sans text-lg tracking-wide">
                  Vitamin B and E infused lenses
                </p>
                <p className="text-lg">for 30% more hydration and 2x</p>
                <p className="text-lg">the comfort</p>
              </>
            )}
            {!!(region === 'US') && (
              <a
                className={`w-9/12 flex button-hero-home mt-8 font-waldo-header ${
                  region === Region.US ? 'btn-cta' : 'btn-cta-uk'
                }`}
                aria-label="Try our lenses for free"
                onClick={() => router.push(props.heroHbpContent.buttonLink)}
              >
                {props.heroHbpContent.buttonLabel}
              </a>
            )}
          </div>
        )}
        {props.isPromoActive ? (
          <div className="flex justify-between mx-auto w-11/12 lg:w-9/12 xl:w-8/12">
            <img
              src={
                isMobile
                  ? props.heroHbpContent.mobileImage_us_promo
                  : props.heroHbpContent.desktopImage_us_promo
              }
              alt={props.heroHbpContent.imageAlt}
              className={`w-[57%] lg:w-[567px] xl:w-[567px] xl:h-[534px] lg:ml-10 lg:mr-3 xl:mr-3 xl:ml-32`}
            />
            <img
              src={
                isMobile
                  ? props.heroHbpContent.mobileImageTwo_promo
                  : props.heroHbpContent.desktopImageTwo_promo
              }
              alt={props.heroHbpContent.imageAlt}
              className={`w-[41%] lg:w-[328px] xl:w-[331px] xl:h-[534px]`}
            />
          </div>
        ) : (
          <div className="flex justify-between mx-auto w-11/12 lg:w-9/12 xl:w-9/12">
            <img
              src={`/images/home/home-hero-left-${
                region === Region.US ? 'US' : 'UK'
              }-${isMobile ? 'M' : 'D'}.png`}
              alt={props.heroHbpContent.imageAlt}
              className={`w-[49%] lg:w-[46%] xl:w-[425px] xl:h-[534px] lg:ml-6 lg:mr-5 xl:mr-7 xl:ml-36`}
            />
            <img
              src={`/images/home/home-hero-right-${isMobile ? 'M' : 'D'}.gif`}
              alt={props.heroHbpContent.imageAlt}
              className={`w-[49%] lg:w-[48%] xl:w-[454px] xl:h-[534px]`}
            />
          </div>
        )}

        {!!(region === 'US') && (
          <div className="flex justify-center w-11/12 mx-auto lg:hidden">
            <a
              className={`w-full md:w-52 text-base mx-auto block button-hero-home mt-6 md:mt-8 font-waldo-header ${
                region === 'US' ? 'btn-cta-hbp' : 'btn-cta-uk'
              }`}
              aria-label="Try our lenses for free"
              onClick={() => router.push(props.heroHbpContent.buttonLink)}
            >
              {props.heroHbpContent.buttonLabel}
            </a>
          </div>
        )}
      </div>
      <div className="flex justify-center md:justify-end mt-5 mx-auto md:mr-8 lg:mr-6">
        <div className="flex items-center text-waldo-blue">
          <span className="font-waldo-regular text-lg md:text-xl mr-4 md:mr-12 mt-1">
            As seen in
          </span>
          <img
            src="/images/home/press-logos.png"
            alt="press logos"
            className="w-[246px] h-[28px] md:w-[326px] md:h-[34px]"
          />
        </div>
        <img
          src={`/images/home/FDA-logo-D.png`}
          alt="FDA logo"
          className="hidden w-[329px] h-[31px] md:w-[503px] md:h-[41px]"
        />
      </div>
    </section>
  );
};
