import React from 'react';
import { Route } from '../../../enums';

export const heroHbpHome = {
  mobileImage_us: '/images/home/hbp-hero-mobile.gif',
  mobileImage: '/images/home/hbp-hero-mobile.gif',
  mobileImageTwo: '/images/home/hbp-hero-two-mobile.png',
  desktopImage_us: '/images/home/hbp-hero-desktop.gif',
  desktopImage: '/images/home/hbp-hero-desktop.gif',
  desktopImageTwo: '/images/home/hbp-hero-two-desktop.png',
  mobileImage_us_promo: '/images/home/US-mobile-promo.png',
  mobileImage_promo: '/images/home/US-mobile-promo.png',
  mobileImageTwo_promo: '/images/home/hbp-hero-two-mobile-glasses.png',
  desktopImage_us_promo: '/images/home/DESKTOP-US.png',
  desktopImage_promo: '/images/home/DESKTOP-US.png',
  desktopImageTwo_promo: '/images/home/hbp-hero-two-desktop-glasses.png',
  imageAlt: 'Affordable contact lenses and eye care',
  imageAltTwo: 'Affordable contact lenses and eye care',
  title: <>No more compromises</>,
  desc: (
    <>Get high quality daily lenses that fit your eyes, budget and schedule.</>
  ),
  buttonLabel: 'GET STARTED',
  buttonLink: Route.GET_STARTED,
};

// image: '/images/home/homepage-hero-sweater-mobile.png',
// imageAlt:
//   'High quality eyecare products, backed by science, without the price tag.',
// title: <>No more compromises</>,
// desc: (
//   <>Get high quality daily lenses that fit your eyes, budget and schedule.</>
// ),
// buttonLabel: 'GET STARTED',
// buttonLink: Route.GET_STARTED,
