import axios from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { appStorage, AppStorageKey } from '../../../helpers';

export interface IpifyDataResponse {
  ip: string;
  location: {
    country: string;
    region: string;
    timezone: string;
  };
  domains: string[];
  as: {
    asn: number;
    name: string;
    route: string;
    domain: string;
    type: string;
  };
  isp: string;
}

export interface IpifyGeolocationResponse {
  timestamp: number;
  response: IpifyDataResponse | null;
  error: any;
}

const ipifyRequest = (requestUrl: string) => axios.get(requestUrl);
const ipifyDebounced = AwesomeDebouncePromise(ipifyRequest, 500);

export const ipifyGeolocationAxios =
  async (): Promise<IpifyGeolocationResponse> => {
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();

    const IPIFY_API_URL =
      'https://geo.ipify.org/api/v2/country?apiKey=at_J4ai8S5JKmvR4w8xA7mM9d8XPM6cC';

    const result: IpifyGeolocationResponse = {
      timestamp: 0,
      response: null,
      error: null,
    };

    try {
      const storedResponse = appStorage.get(
        AppStorageKey.GEOLOCATION_RESPONSE,
        false
      ) as IpifyGeolocationResponse | false;

      if (
        storedResponse &&
        (storedResponse.response ||
          currentTimestamp - storedResponse.timestamp < 60000)
      ) {
        return storedResponse;
      }

      const ipifyResponse = await ipifyDebounced(IPIFY_API_URL);
      const responseData = ipifyResponse.data as IpifyDataResponse;

      result.timestamp = currentDate.getTime();
      result.response = responseData;
    } catch (errorResponse) {
      result.timestamp = currentDate.getTime();
      result.error = errorResponse;
    }

    appStorage.set(AppStorageKey.GEOLOCATION_RESPONSE, result);

    return result;
  };
