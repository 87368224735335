import { createUseStyles } from 'react-jss';

export const useHomePressStyles = createUseStyles({
  homePress: {
    '& .business-max': {
      maxWidth: '70px',
      '@media (min-width: 1024px)': {
        maxWidth: '115px',
      },
    },
    '& .gq-max': {
      maxWidth: '41px',
      '@media (min-width: 1024px)': {
        maxWidth: '82px',
      },
    },
    '& .forbes-max': {
      maxWidth: '63px',
      '@media (min-width: 1024px)': {
        maxWidth: '120px',
      },
    },
    '& .refinery-max': {
      maxWidth: '63px',
      '@media (min-width: 1024px)': {
        maxWidth: '160px',
      },
    },
    '& .marie-max': {
      maxWidth: '51px',
      '@media (min-width: 1024px)': {
        maxWidth: '162px',
      },
    },
  },
});
