import { Region } from '@hiwaldo/sdk';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as environment from '../../environment';
import { ipifyGeolocationAxios } from '../../server';
import { StoreState } from '../../state';
import { geolocationSlice } from '../../state/redux/common/geolocation';
import {
  appStorage,
  AppStorageKey,
  currentEnvironment,
  currentRegion,
} from '../../helpers';

export type EnvApplicationKey =
  | 'apollo-gateway'
  | 'apollo-external'
  | 'loqate'
  | 'domain'
  | 'hostname'
  | 'optimize'
  | 'gtm'
  | 'stripe'
  | 'paypal';

export function useWindow() {
  const router = useRouter();
  const dispatch = useDispatch();

  const { geolocation } = useSelector((state: StoreState) => {
    return {
      geolocation: state.common.geolocation,
    };
  });

  const [mounted, setMounted] = useState<boolean>(false);
  const [resized, setResized] = useState<boolean>(false);
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [route, setRoute] = useState<string | undefined>(undefined);
  const [hostname, setHostname] = useState<string | undefined>(undefined);
  const [origin, setOrigin] = useState<string | undefined>(undefined);
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const region = currentRegion();
  const application = currentEnvironment();

  const getEnv = (
    field: EnvApplicationKey,
    store: Region | 'DOCTOR'
  ): string => {
    switch (field) {
      case 'apollo-gateway':
        return environment.envApolloUrls[application].gateway;
      case 'apollo-external':
        return environment.envApolloUrls[application].external;
      case 'loqate':
        return environment.envLoqateKeys[application].key;
      case 'domain':
        return environment.envHostnames.domain[application][store];
      case 'hostname':
        return environment.envHostnames.host[application][store];
      case 'optimize':
        return environment.envGTMKeys[application] &&
          environment.envGTMKeys[application][store as Region]
          ? environment.envGTMKeys[application][store as Region]
          : '';
      case 'gtm':
        return environment.envGTMKeys[application] &&
          environment.envGTMKeys[application][store as Region]
          ? environment.envGTMKeys[application][store as Region]
          : '';
      case 'stripe':
        return environment.envStripeKeys[application] &&
          environment.envStripeKeys[application][store as Region]
          ? environment.envStripeKeys[application][store as Region]
          : '';
      case 'paypal':
        return environment.envPayPalUrls[application] &&
          environment.envPayPalUrls[application][store as Region]
          ? environment.envPayPalUrls[application][store as Region]
          : '';
    }
  };

  const changeStore = (store: Region, page = ''): void => {
    let location;

    let US = 'www.hiwaldo.com';
    let UK = 'uk.hiwaldo.com';
    let EU = 'eu.hiwaldo.com';
    let SE = 'se.hiwaldo.com';

    if (environment.envHostnames.domain[application]) {
      US = environment.envHostnames.domain[application].US;
      UK = environment.envHostnames.domain[application].UK;
      EU = environment.envHostnames.domain[application].EU;
      SE = environment.envHostnames.domain[application].SE;
    }

    switch (store) {
      case Region.UK:
        location = UK;
        break;
      case Region.SE:
        location = SE;
        break;
      case Region.EU:
        location = EU;
        break;
      case Region.US:
      default:
        location = US;
        break;
    }

    setRedirect(`${location}${page}`);
  };

  const checkCurrentGeolocation = async () => {
    const storedGeolocationResponse = appStorage.get(
      AppStorageKey.GEOLOCATION_RESPONSE,
      false
    );

    if (!storedGeolocationResponse) {
      dispatch(
        geolocationSlice.actions.updateGeolocation({
          ...geolocation,
          loading: true,
        })
      );

      const currentStore = region;

      const getLocation = await ipifyGeolocationAxios();

      if (getLocation.response) {
        const {
          location: { country },
        } = getLocation.response;

        let updatedRegion: Region;

        switch (country) {
          case 'GB':
            updatedRegion = Region.UK;
            break;
          case 'US':
            updatedRegion = Region.US;
            break;
          case 'SE':
            updatedRegion = Region.SE;
            break;
          default:
            updatedRegion = Region.EU;
        }

        dispatch(
          geolocationSlice.actions.updateGeolocation({
            ...geolocation,
            loading: false,
            redirected: true,
            geoRegion: updatedRegion,
            country,
          })
        );

        if (currentStore !== updatedRegion) {
          changeStore(updatedRegion);
        }
      } else {
        dispatch(
          geolocationSlice.actions.updateGeolocation({
            ...geolocation,
            loading: false,
            redirected: false,
            geoRegion: currentStore,
            country: currentStore,
          })
        );
      }
    }
  };

  const setCurrentGeolocation = async (selectedRegion: Region) => {
    changeStore(selectedRegion!);
  };

  useEffect(() => {
    if (!mounted) setMounted(true);

    if (mounted) {
      const handleRouteChange = (url: string) => {
        if (url !== route) {
          setRoute(url);

          const location = window.location.hostname;
          const origin = window.location.origin;

          if (location && origin) {
            setHostname(location);
            setOrigin(origin);

            checkCurrentGeolocation();
          }
        }
      };

      const handleResize = () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
      };

      if (typeof redirect !== 'undefined') {
        setRedirect(undefined);
        window.location.href = redirect;
      }

      if (!geolocation.country && !loading) {
        setLoading(true);
        checkCurrentGeolocation();
      }

      window.addEventListener('resize', handleResize);

      if (!resized) {
        setResized(true);
        handleResize();
      }

      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [geolocation, resized, redirect, loading, mounted]);

  return {
    getEnv,
    setCurrentGeolocation,
    checkCurrentGeolocation,
    changeStore,
    geolocation,
    route,
    hostname,
    origin,
    region,
    height,
    width,
    debug: application !== 'prod',
  };
}
