import { createUseStyles } from 'react-jss';

export const useHomeWaldoDifferenceStyles = createUseStyles({
  whyLenses: {
    '& .allDay-max': {
      maxWidth: '60px',
    },
    '& .flexible-max': {
      maxWidth: '74px',
    },
    '& .free-shipping-max': {
      maxWidth: '125px',
    },
    '& .doctor-max': {
      maxWidth: '84px',
    },
  },
});
