export const pressImages = [
  {
    image: 'business_insider.svg',
    title: 'Business Insider',
    max: 'business-max',
    order: 1,
  },
  {
    image: 'gq.svg',
    title: 'GQ',
    max: 'gq-max',
    order: 2,
  },
  {
    image: 'forbes.svg',
    title: 'Forbes',
    max: 'forbes-max',
    order: 3,
  },
  {
    image: 'refinery29.svg',
    title: 'Refinery29',
    max: 'refinery-max',
    order: 4,
  },
];

const duplicatePress = pressImages;

export const pressImageMobile = duplicatePress.sort(function (a, b) {
  return a.order - b.order;
});
