import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import {
  HomeOurProductsOrganism,
  HomeWaldoDifferenceOrganism,
  HomePressOrganism,
  HomeWhyLensesOrganism,
  HomeReviewsOrganism,
} from '../modules';
import { MetaData } from '../enums';
import { HomeHeroHbpOrganism } from '../modules/organisms/home-hero-hbp';
import { heroHbpHome } from '../data/pages/home/hero-hbp';
import { useGoogleTagManagerEffect } from '../state';
import { staticPageDefault, DefaultPageProps } from '../server';
import { useBridgePageEffect, useCampaignEffect } from '../hooks';
import { FooterPartial, HeaderPartial } from '../components';

/**
 * @description Implementing a default static page, which includes active promotions.
 */
export const getStaticProps = staticPageDefault;

const Index = (props: DefaultPageProps) => {
  const announcement = false;
  const bodyTop = announcement ? 'bodyTopAnnouncement' : 'bodyTopDefault';

  const [hasLoaded, setLoaded] = useState(false);
  const { gtmProductHomeView } = useGoogleTagManagerEffect();
  useEffect(() => {
    if (!hasLoaded) {
      setLoaded(true);
      gtmProductHomeView();
    }
  }, [gtmProductHomeView, hasLoaded]);

  // Pass active promotions to the campaign effect hook
  const { active } = useCampaignEffect(props.activePromotions);
  const bridge = useBridgePageEffect({
    isAccount: false,
    condensedFooter: false,
    activeFeatures: props.activeFeatures,
  });

  return (
    <>
      <>
        <Head>
          <title>{MetaData.defaultTitle}</title>
        </Head>
        <HeaderPartial {...bridge.header}></HeaderPartial>
        <main role="main" className={bodyTop}>
          <HomeHeroHbpOrganism
            heroHbpContent={heroHbpHome}
            isPromoActive={active}
            activeFeatures={props.activeFeatures}
          />
          <HomeOurProductsOrganism isPromoActive={active} />
          <HomeWaldoDifferenceOrganism />
          <HomePressOrganism />
          <HomeWhyLensesOrganism />
          <HomeReviewsOrganism />
          {/* <SiteSaversOrganism /> */}
        </main>
        <FooterPartial {...bridge.footer}></FooterPartial>
      </>
    </>
  );
};

export default Index;
