import React from 'react';
import { Route } from '../../../enums';
import { useWindow } from '../../../effects';

export const HomeReviewsOrganism = () => {
  const { region } = useWindow();

  return (
    <section className="w-full bg-waldo-dirty-white pt-8 pb-10 xl:pt-10 xlpb-12">
      <div className="w-11/12 lg:w-full max-w-screen-lg mx-auto">
        <h2 className="text-waldo-blue text-4xl font-medium text-center">
          Over 11,000 five-star reviews
        </h2>
        {!!(region === 'US') && (
          <a
            className={`w-11/12 md:w-52 font-waldo-header mx-auto button-second ${
              region === 'US' ? 'btn-cta-hbp' : 'btn-cta-uk'
            } block text-center mt-10 mb-6 lg:mb-7`}
            href={Route.GET_STARTED}
            aria-label="Try our lenses for free"
          >
            GET STARTED
          </a>
        )}
      </div>
    </section>
  );
};
