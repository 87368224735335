import React from 'react';
import { pressImageMobile, pressImages } from '../../../data/pages/home/press';
import { useHomePressStyles } from '../../../css/organisms/home-press.styles';

export const HomePressOrganism = () => {
  const classes = useHomePressStyles();
  return (
    <section className={`w-full bg-waldo-blue py-5 ${classes.homePress}`}>
      <div className="w-11/12 lg:w-full max-w-screen-lg mx-auto">
        <h2 className="text-center text-white text-4xl font-waldo-regular">
          &quot;Premium quality lenses made affordable&quot;
        </h2>
        <div className="hidden lg:flex flex-row w-full justify-between items-center mt-10 mb-5">
          {pressImages.map((press, index) => (
            <img
              key={index}
              className={`w-1/6 ${press.max} `}
              src={`/images/home/press/${press.image}`}
              alt={press.title}
            />
          ))}
        </div>

        <div className="flex lg:hidden flex-row w-full justify-between items-center mt-10 mb-5">
          {pressImageMobile.map((press, index) => (
            <img
              key={index}
              className={`w-1/6 ${press.max} `}
              src={`/images/home/press/mobile/${press.image}`}
              alt={press.title}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
