import React from 'react';
import { Route } from '../../../enums';
import {
  whyLenses,
  whyLensesMobile,
} from '../../../data/pages/home/why-lenses';
import { useHomeWaldoDifferenceStyles } from '../../../css/organisms/home-why-lenses.styles';
import { useWindow } from '../../../effects';

export const HomeWhyLensesOrganism = () => {
  const { region } = useWindow();

  const classes = useHomeWaldoDifferenceStyles();
  return (
    <section
      className={`w-11/12 max-w-screen-lg bg-white py-5 mx-auto ${classes.whyLenses}`}
    >
      <h2 className="text-center text-waldo-blue text-4xl lg:mt-4 lg:mb-4">
        Why our lenses?
      </h2>
      <div className="flex flex-col lg:flex-row w-full lg:justify-between lg:items-end mt-5 l:mt-6 mb-5 md:hidden">
        {whyLensesMobile.map((lens, index) => (
          <div className="flex flex-col lg:w-auto mt-8 lg:mt-0" key={index}>
            <div className="flex flex-col justify-between justify-items-end">
              <img
                className={`w-full box-border mx-auto block ${lens.addClass}`}
                src={`/images/home/why-our-lenses/${lens.image}`}
                alt={lens.title}
              />
            </div>
            <div className="flex flex-col justify-between">
              <p className="font-semibold text-lg text-center mt-5 leading-snug">
                {lens.title}
              </p>
              <p
                className={`text-lg text-center mt-2 leading-snug ${lens.textClass}`}
              >
                {lens.desc1}
              </p>
              <p
                className={`text-lg text-center leading-snug ${lens.textClass}`}
              >
                {lens.desc2}
              </p>
              <p
                className={`text-lg text-center leading-snug ${lens.textClass}`}
              >
                {lens.desc3}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden md:flex flex-col lg:flex-row w-full lg:justify-between lg:items-end mt-5 l:mt-6 mb-5">
        {whyLenses.map((lens, index) => (
          <div className="flex flex-col lg:w-auto mt-8 lg:mt-0" key={index}>
            <div className="flex flex-col justify-between justify-items-end">
              <img
                className={`w-full box-border mx-auto block ${lens.addClass}`}
                src={`/images/home/why-our-lenses/${lens.image}`}
                alt={lens.title}
              />
            </div>
            <div className="flex flex-col justify-between">
              <p className="font-semibold text-lg text-center mt-5 leading-snug">
                {lens.title}
              </p>
              <p
                className={`text-lg text-center mt-2 leading-snug ${lens.textClass}`}
              >
                {lens.desc1}
              </p>
              <p
                className={`text-lg text-center leading-snug ${lens.textClass}`}
              >
                {lens.desc2}
              </p>
              <p
                className={`text-lg text-center leading-snug ${lens.textClass}`}
              >
                {lens.desc3}
              </p>
            </div>
          </div>
        ))}
      </div>
      {!!(region === 'US') && (
        <a
          className={`w-11/12 md:w-52 font-waldo-header mx-auto button-second ${
            region === 'US' ? 'btn-cta-hbp' : 'btn-cta-uk'
          } block text-center mt-10 mb-6 lg:mb-7`}
          href={Route.GET_STARTED}
        >
          GET STARTED
        </a>
      )}
    </section>
  );
};
