import { createUseStyles } from 'react-jss';

export const useHomeWaldoDifferenceStyles = createUseStyles({
  waldoDifference: {
    '& .diff-top-left': {
      width: '147px',
      left: '5%',
      '@media (min-width: 1024px)': {
        width: '200px',
        left: '15%',
      },
    },
    '& .diff-bottom-left': {
      width: '140px',
      bottom: '-120px',
      left: '5%',
      '@media (min-width: 1024px)': {
        width: '200px',
        bottom: '-105px',
        left: '15%',
      },
    },
    '& .diff-top-right': {
      width: '110px',
      right: '5%',
      '@media (min-width: 1024px)': {
        width: '140px',
        right: '20%',
      },
    },
    '& .diff-bottom-right': {
      width: '130px',
      right: '5%',
      bottom: '-110px',
      '@media (min-width: 1024px)': {
        width: '150px',
        right: '28%',
        bottom: '-105px',
      },
    },
  },
});
