import { useSelector } from 'react-redux';
import { StoreState } from '../../state';
import { HeaderProps, FooterProps } from '../../components';
import {
  HeaderBasket,
  PageEnvironmentDisplayType,
  usePageEnvironmentEffect,
  usePageFooterEffect,
  usePageHeaderEffect,
  useRoutingEffect,
  useUpsellEffect,
} from '../common';
import {
  useBasketTrialEffect,
  useBasketProductVariantEffect,
  useBasketTotalEffect,
  useBasketSubscriptionEffect,
  useBasketDisplayEffect,
} from '../basket';
import { SiteFeatureList } from '../../server';

export interface BridgePageProps {
  isAccount?: boolean;
  isFunnel?: boolean;
  activeFeatures?: SiteFeatureList;
  condensedFooter: boolean;
}

export interface BridgePageEffect {
  display: PageEnvironmentDisplayType;
  header: HeaderProps;
  footer: FooterProps;
}

export const useBridgePageEffect = (
  props: BridgePageProps
): BridgePageEffect => {
  const { display, region } = usePageEnvironmentEffect();

  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const basketTrial = useBasketTrialEffect();
  const basketProductVariant = useBasketProductVariantEffect();
  const basketTotals = useBasketTotalEffect();
  const basketSubscription = useBasketSubscriptionEffect();
  const basketDisplay = useBasketDisplayEffect();

  const upsell = useUpsellEffect(
    basketDataState,
    basketProductVariant,
    region,
    false
  );

  const basket: HeaderBasket = {
    basketTrial,
    basketProductVariant,
    basketTotals,
    basketSubscription,
    basketDisplay,
    upsell: upsell?.basket,
    cartId: basketDataState.cart?.id || undefined,
    cartItems: basketDataState.countItems,
  };

  const routing = useRoutingEffect(
    props.activeFeatures ? props.activeFeatures : undefined
  );

  const footer = usePageFooterEffect(
    props.condensedFooter ? 'footerCondensed' : 'footerDefault',
    routing,
    display,
    region
  );

  const header = usePageHeaderEffect({
    template: 'headerDefault',
    styleClasses: ['headerBase'],
    routing,
    display,
    basket,
    account: props.isAccount ? props.isAccount : undefined,
    funnel: props.isFunnel ? props.isFunnel : undefined,
    activeFeatures: props.activeFeatures ? props.activeFeatures : undefined,
  });

  return {
    display,
    header,
    footer,
  };
};
