export const whyLenses = [
  {
    title: 'All day comfort',
    desc1: 'High water content and oxygen',
    desc2: 'transmissibility means optimum',
    desc3: 'comfort throughout the day.',
    image: 'all-day-comfort.svg',
    addClass: 'allDay-max mt-0 lg:mt-11',
    textClass: 'w-[224px]',
  },
  {
    title: 'Flexible subscription',
    desc1: 'Adjust your delivery as',
    desc2: 'needed. Pause or ',
    desc3: 'cancel, it’s hassle-free.',
    image: 'flexible-subsription.svg',
    addClass: 'flexible-max mt-0 lg:mt-10',
    textClass: 'max-w-[18px]',
  },
  {
    title: 'Free shipping',
    desc1: 'Fast and free delivery on.',
    desc2: 'all orders following your',
    desc3: '10-day trial.',
    image: 'free-shipping.svg',
    addClass: 'free-shipping-max lg:mt-5',
    textClass: 'w-[192px]',
  },
  {
    title: 'Doctor and customer approved',
    desc1: 'High-quality lenses loved',
    desc2: 'by customers and endorsed by',
    desc3: 'experts.',
    image: 'doctor_customer_approved.svg',
    addClass: 'doctor-max mt-0 lg:mt-16',
    textClass: 'w-[225px]',
  },
];

export const whyLensesMobile = [
  {
    title: 'All day comfort',
    desc1: 'High water content and oxygen',
    desc2: 'transmissibility means optimum comfort',
    desc3: 'throughout the day.',
    image: 'all-day-comfort.svg',
    addClass: 'allDay-max mt-0 lg:mt-11',
    textClass: 'w-[224px]',
  },
  {
    title: 'Flexible subscription',
    desc1: 'Adjust your delivery as needed.',
    desc2: 'Pause or cancel, it’s hassle-free.',
    desc3: '',
    image: 'flexible-subsription.svg',
    addClass: 'flexible-max mt-0 lg:mt-10',
    textClass: 'max-w-[18px]',
  },
  {
    title: 'Free shipping',
    desc1: 'Fast and free delivery on all orders',
    desc2: 'following your 10-day trial.',
    desc3: '',
    image: 'free-shipping.svg',
    addClass: 'free-shipping-max lg:mt-5',
    textClass: 'w-[192px]',
  },
  {
    title: 'Doctor and customer approved',
    desc1: 'High-quality lenses loved',
    desc2: 'by customers and endorsed by experts.',
    desc3: '',
    image: 'doctor_customer_approved.svg',
    addClass: 'doctor-max mt-0 lg:mt-16',
    textClass: 'w-[225px]',
  },
];
