import React from 'react';
import { Route } from '../../../enums';
import { useHomeWaldoDifferenceStyles } from '../../../css/organisms/home-waldo-difference.styles';
import { useWindow } from '../../../effects';

export const HomeWaldoDifferenceOrganism = () => {
  const { region } = useWindow();

  const classes = useHomeWaldoDifferenceStyles();
  return (
    <section
      className={`${classes.waldoDifference} bg-waldo-dirty-white w-full xsm:mt-14 lg:mt-12 pt-4 pb-12`}
    >
      <div className="w-11/12 lg:w-full max-w-screen-xl mx-auto py-5 l:py-10">
        <h2 className="text-center text-4xl font-bold font-waldo-regular md:pb-6">
          What sets us apart?
        </h2>
        <div className="relative mt-5 mb-12">
          <div className="absolute diff-top-left xl:ml-[5.5rem]">
            <p className="font-semibold text-lg xl:text-2xl hidden lg:block lg:w-64 lg:mt-4">
              High-quality eyecare
            </p>
            <p className="font-semibold text-lg xl:text-2xl block lg:hidden">
              High-quality
            </p>
            <p className="text-base xl:text-xl w-full xl:w-54">
              Market-leading quality, recommended
            </p>
            <p className="text-base xl:text-xl w-full xl:w-54">by doctors</p>
            <img
              className="hidden lg:block absolute right-0 xl:right-4 -mt-10"
              src="/images/home/difference/arrows/home-arrow-top-left.svg"
              alt="left arrow"
            />
            <img
              className="block lg:hidden absolute right-0 -mt-5"
              src="/images/home/difference/arrows/mobile/home-arrow-top-left-mobile.svg"
            />
          </div>

          <div className="absolute diff-bottom-left xl:ml-[5rem]">
            <img
              className="hidden lg:block absolute xl:-top-4 right-1/2 left-1/2 xl:right-4 xl:left-[5.5rem] h-7"
              src="/images/home/difference/arrows/home-arrow-bottom-left.svg"
            />
            <img
              className="block lg:hidden absolute right-1/2 left-3/20 h-8"
              src="/images/home/difference/arrows/mobile/home-arrow-bottom-left-mobile.svg"
            />
            <p className="font-semibold text-lg xl:text-2xl mt-10 lg:mt-6">
              Expert-endorsed
            </p>
            <p className="text-base xl:text-xl w-full lg:w-44 xl:w-56 mt-0">
              Let our in-house optician answer your questions
            </p>
          </div>

          <div className="absolute top-0 lg:top-4 diff-top-right xl:mr-[4.3rem]">
            <p className="font-semibold text-lg xl:text-2xl text-right">
              Affordable
            </p>
            <p className="w-full xl:w-[8rem] text-base xl:text-xl text-right xl:ml-[0.8rem]">
              Save over 40% vs other big name brands
            </p>
            <img
              className="hidden lg:block absolute top-8 -left-5 w-8 h-5"
              src="/images/home/difference/arrows/home-arrow-top-right.svg"
              alt="arrow"
            />
            <img
              className="block lg:hidden absolute top-20 left-0 mt-0"
              src="/images/home/difference/arrows/mobile/home-arrow-top-right-mobile.svg"
              alt="arrow"
            />
          </div>

          <div className="absolute diff-bottom-right xl:mr-[3rem]">
            <img
              className="hidden lg:block absolute left-1/2 xl:ml-2 xl:-top-3"
              src="/images/home/difference/arrows/home-arrow-bottom-right.svg"
              alt="arrow"
            />
            <img
              className="block lg:hidden absolute left-1/2"
              src="/images/home/difference/arrows/mobile/home-arrow-bottom-right-mobile.svg"
              alt="arrow"
            />
            <p className=" xl:w-[12rem] font-semibold text-lg xl:text-2xl mt-10 text-right block">
              Convenient
            </p>
            <p className="text-base xl:text-xl w-full mt-0 text-right xl:w-[12rem] xl:mr-[12rem]">
              Free and flexible delivery to your door
            </p>
          </div>
          <img
            className="hidden lg:block w-3/4 box-border max-w-screen-sm mx-auto pl-20 pt-20 xl:-mt-[2rem]"
            src="/images/home/difference/waldo_difference_home.png"
            alt="affordable quality eyecare"
          />
          <div className="block lg:hidden w-93/100 pt-28 mx-auto pl-10">
            <img
              className="border-box w-full"
              src="/images/home/difference/waldo_difference_home.png"
              alt="affordable quality eyecare"
            />
          </div>
        </div>
      </div>
      {!!(region === 'US') && (
        <a
          className={`font-waldo-header mx-auto button-second ${
            region === 'US' ? 'btn-cta-hbp' : 'btn-cta-uk'
          } block text-center w-10/12 mt-24 md:w-52`}
          href={Route.GET_STARTED}
        >
          GET STARTED
        </a>
      )}
    </section>
  );
};
